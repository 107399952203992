import React, { useState } from "react";
// import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Checkbox, MenuProps } from "antd";

const Filter = React.memo(() => {
  const [open, setOpen] = useState(false);

  const handleOpenChange = (nextOpen: boolean) => {
    setOpen(nextOpen);
  };

  const items: MenuProps["items"] = [
    {
      key: "All",
      label: (
        <Checkbox
          className="dropdown-row flex-row-reverse"
          // checked={selectedData?.length === data?.length}
          checked={false}
          onChange={() => {
            // handleSelectAll(e);
          }}
        >
          {"test"}
        </Checkbox>
      ),
    },
    // ...(data || []).map((item: any, index: any) => ({
    //   key: index,
    //   label: (
    //     <Checkbox
    //       className="dropdown-row flex-row-reverse"
    //       checked={selectedData.includes(item.id)}
    //       onChange={(e) => {
    //         handleCheckboxChange(e, item);
    //       }}
    //     >
    //       {title === "All Users"
    //         ? `${item.firstName} ${item.lastName}`
    //         : title === "All Sections"
    //         ? item.title
    //         : item?.name}
    //     </Checkbox>
    //   ),
    // })),
  ];

  return (
    <Dropdown
      menu={{
        items,
        getPopupContainer: () =>
          document.getElementById("drop-down-id") as HTMLDivElement,
        selectable: true,
        multiple: true,
        onClick: ({ domEvent }) => {
          domEvent.stopPropagation();
        },
      }}
      onOpenChange={handleOpenChange}
      open={open}
      trigger={["click"]}
    >
      <a
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        test
      </a>
    </Dropdown>
  );
});

export default Filter;
