import React from "react";
import { Input, Button } from "antd";
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import * as Yup from "yup";
import AuthAside from "Components/AuthAside/AuthAside";
import AuthFooter from "Components/AuthFooter/AuthFooter";

const validationSchema = Yup.object({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), ""], "Passwords must match")
    .required("Confirm Password is required"),
});

interface FormValues {
  password: string;
  confirmPassword: string;
}

const ResetPassword: React.FC = () => {
  const initialValues: FormValues = { password: "", confirmPassword: "" };

  const handleSubmit = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    console.log(values);
    setSubmitting(false);
  };

  return (
    <div className="auth-grid">
      <div className="auth-dialog-container">
        <div className="auth-dialog">
          <div className="text-center text-brand-color mb-36">
            <div className="h1 mt-56">New Password</div>
            <span className="vl-hint mt-12">
              Please create a new password that <br />
              you don’t use on any other site.
            </span>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, handleBlur }) => (
              <Form>
                <div className="vl-fieldset is-vertical">
                  <div className="vl-form-group is-vertical">
                    <label className="vl-label">New Password</label>
                    <Field
                      as={Input}
                      type="password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="vl-error"
                    />
                  </div>
                  <div className="vl-form-group is-vertical">
                    <label className="vl-label">Confirm New Password</label>
                    <Field
                      as={Input}
                      type="password"
                      name="confirmPassword"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.confirmPassword}
                    />
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="vl-error"
                    />
                  </div>
                  <Button type="primary" htmlType="submit">
                    Continue
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <AuthAside />
      <AuthFooter />
    </div>
  );
};

export default ResetPassword;
