import React from "react";
import { Link } from "react-router-dom";
import url from "svg/user.png";

interface UserDataProps {
  url?: string;
  key: string;
  firstName: string;
  lastName: string;
  email: string;
  nickName: string;
  role: string;
}

interface UserCardProps {
  dataSource: UserDataProps[];
}

const UserCard: React.FC<UserCardProps> = ({ dataSource }) => {
  return (
    <div>
      {dataSource.map((user: UserDataProps) => (
        <div key={user.key}>
          <Link to={`/user/${user.key}`}>
            <img src={user.url || url} alt="" />
            <h2>
              {user.firstName} {user.lastName}
            </h2>
            <p>Email: {user.email}</p>
            <p>Nickname: {user.nickName}</p>
            <p>Role: {user.role}</p>
          </Link>
          <button>DELETE</button>
        </div>
      ))}
    </div>
  );
};

export default UserCard;
