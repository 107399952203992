import React from "react";
import { Input, Button } from "antd";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import AuthAside from "Components/AuthAside/AuthAside";
import AuthFooter from "Components/AuthFooter/AuthFooter";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[A-Z])(?=.*[0-9]).{8,}$/,
      "Password must contain at least one uppercase letter and one digit"
    ),
});

const ForgotPassword = () => {
  return (
    <div className="auth-grid">
      <div className="auth-dialog-container">
        <div className="auth-dialog">
          <div className="text-center text-brand-color mb-36">
            <div className="h1 mt-56">Forgot Password</div>
            <span className="vl-hint mt-12">Enter your email address</span>
          </div>
          <Formik
            initialValues={{ email: "", password: "", rememberMe: false }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              console.log(values);
            }}
          >
            {({ values, handleChange, handleBlur }) => (
              <Form>
                <div className="vl-fieldset is-vertical">
                  <div className="vl-form-group is-vertical">
                    <label className="vl-label">Email address</label>
                    <Field
                      as={Input}
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <ErrorMessage name="email" component="div" className="vl-error" />
                  </div>
                  <Button type="primary" htmlType="submit">
                    Continue
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <AuthAside />
      <AuthFooter />
    </div>
  );
};

export default ForgotPassword;
