import React, { useState } from "react";
import { Input, Button } from "antd";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import AuthAside from "Components/AuthAside/AuthAside";
import AuthFooter from "Components/AuthFooter/AuthFooter";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    ),
});

const getPasswordStrength = (password: string) => {
  if (password.length < 8) {
    return "weak";
  }
  const hasLowerCase = /[a-z]/.test(password);
  const hasUpperCase = /[A-Z]/.test(password);
  const hasDigit = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*()?]/.test(password);

  if (
    password.length >= 8 &&
    hasLowerCase &&
    hasUpperCase &&
    hasDigit &&
    hasSpecialChar
  ) {
    return "strong";
  } else if (
    password.length >= 8 &&
    (hasLowerCase || hasUpperCase || hasDigit || hasSpecialChar)
  ) {
    return "medium";
  } else {
    return "weak";
  }
};

const ChangePassword: React.FC = () => {
  const [passwordStrength, setPasswordStrength] = useState<string>("");
  const [passwordTouched, setPasswordTouched] = useState<boolean>(false);

  return (
    <div className="auth-grid">
      <div className="auth-dialog-container">
        <div className="auth-dialog">
          <div className="text-center text-brand-color mb-36">
            <div className="h1 mt-56">Change your password</div>
            <span className="vl-hint mt-12">Please create a new password that you don’t use on any other site.</span>
          </div>
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              console.log(values);
            }}
          >
            {({ values, handleChange, handleBlur }) => (
              <Form>
                <div className="vl-fieldset is-vertical">
                  <div className="vl-form-group is-vertical">
                    <label className="vl-label">Enter your email*</label>
                    <Field
                      as={Input}
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <ErrorMessage name="email" component="div" className="vl-error" />
                  </div>
                  <div className="vl-form-group is-vertical">
                    <label className="vl-label">Create New password*</label>
                    <Field
                      as={Input.Password}
                      name="password"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleChange(e);
                        setPasswordTouched(true);
                        setPasswordStrength(getPasswordStrength(e.target.value));
                      }}
                      onBlur={handleBlur}
                      value={values.password}
                      iconRender={(visible: boolean) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="vl-error"
                    />
                    {passwordTouched && (
                      <div className="passwordStrength">
                        <div
                          className={`strength-bar ${
                            passwordStrength === "weak" ? "weak" : ""
                          }`}
                        />
                        <div
                          className={`strength-bar ${
                            passwordStrength === "medium" ? "medium" : ""
                          }`}
                        />
                        <div
                          className={`strength-bar ${
                            passwordStrength === "strong" ? "strong" : ""
                          }`}
                        />
                      </div>
                    )}
                  </div>
                  <Button type="primary" htmlType="submit">
                    Continue
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <AuthAside />
      <AuthFooter />
    </div>
  );
};

export default ChangePassword;
