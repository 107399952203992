import React from "react";
import { Input, Checkbox, Button } from "antd";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import logo from "svg/logo.svg";
import AuthAside from "Components/AuthAside/AuthAside";
import AuthFooter from "Components/AuthFooter/AuthFooter";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[A-Z])(?=.*[0-9]).{8,}$/,
      "Password must contain at least one uppercase letter and one digit"
    ),
});

const Login = () => {
  return (
    <div className="auth-grid">
      <div className="auth-dialog-container">
        <div className="auth-dialog">
          <div className="text-center text-brand-color mb-36">
            <img src={logo} style={{ width: "218px" }} alt="" />
            <div className="h2 mt-16">Company admin</div>
            <div className="h1 mt-56">Login to your account</div>
          </div>
          <Formik
            initialValues={{ email: "", password: "", rememberMe: false }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              console.log(values);
            }}
          >
            {({ values, handleChange, handleBlur }) => (
              <Form>
                <div className="vl-fieldset is-vertical">
                  <div className="vl-form-group is-vertical">
                    <label className="vl-label">Email address</label>
                    <Field
                      as={Input}
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <ErrorMessage name="email" component="div" className="vl-error" />
                  </div>
                  <div className="vl-form-group is-vertical">
                    <label className="vl-label">Password</label>
                    <Field
                      as={Input}
                      type="password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="vl-error"
                    />
                  </div>
                  <div className="display-flex justify-content-space-between">
                    <div>
                      <Field
                        as={Checkbox}
                        name="rememberMe"
                        checked={values.rememberMe}
                        onChange={handleChange}
                      />
                      <label className="ml-8">Remember me</label>
                    </div>
                    <Link to="" className="vl-hint-link">
                      Forgot your password?
                    </Link>
                  </div>
                  <Button type="primary" htmlType="submit">
                    Login
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <AuthAside />
      <AuthFooter />
    </div>
  );
};

export default Login;
