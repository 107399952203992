import React, { useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import styles from "./cropper.module.scss"

const CropperImage: React.FC = () => {
  const [image, setImage] = useState("");
  const [cropData, setCropData] = useState("");
  const [cropper, setCropper] = useState<any>();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const files: FileList | null = e.target.files;
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result as string);
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const onCropEnd = () => {
    if (cropper) {
      setCropData(cropper.getCroppedCanvas().toDataURL());
      console.log(cropData);
    }
  };

  return (
    <div className="display-flex align-items-center flex-direction-column">
      <Cropper
        style={{ width: "100%" }}
        zoomTo={0.5}
        initialAspectRatio={1}
        preview=".img-preview"
        src={image}
        viewMode={1}
        minCropBoxHeight={10}
        minCropBoxWidth={10}
        background={false}
        responsive={true}
        autoCropArea={1}
        checkOrientation={false}
        onInitialized={(instance) => {
          setCropper(instance);
        }}
        guides={true}
        cropend={onCropEnd}
      />

      {!image && (
        <div className={styles["image-placeholder"]}>
          <div className={styles["image-placeholder-circle"]}></div>
          <span className="vl-hint">Drop your new profile image here (max 10MB)</span>
        </div>
      )}

      {cropData && (
        <div>
          <h3>Результат обрезки:</h3>
          <img src={cropData} alt="cropped" />
        </div>
      )}

      <input className="mt-16" type="file" onChange={onChange} />
    </div>
  );
};

export default CropperImage;
