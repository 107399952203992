import React from 'react';

const AuthFooter = () => {
  return (
    <footer className="auth-dialog-footer" style={{ backgroundColor: "#F5FCFF" }}>
      <div className="auth-dialog-footer-inner">
        <p>
          We respect your privacy and security. Please contact us if you have any questions or comments. Tel: +44 (0)207 788 7821 or USA Freecall : 1-866 347 7468.Postal Address: VisionsLive Ltd, 4 Old Park Lane, Mayfair, W1K 1QW, United Kingdom. 2022-03-02 17:16
        </p>
        <p>
          We provide the right set of tools for making real, face-to-face online qualitative research a reality. Tools for real online qualitative research <a href="www.visionslive.com"><b>www.visionslive.com</b></a> ©&nbsp;2023. All rights reserved. <a href="www.visionslive.com"><b>Terms Of Use For The VisionsLive Software Platforms Third Party Notices</b></a>
        </p>
      </div>
    </footer>
  );
};

export default AuthFooter;