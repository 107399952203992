import React from "react";
import UserCard from "Components/UserCard/UserCard";
// import Search from "Components/Search/Search";
import Filter from "Components/Filter/Filter";
// import { Button, Space } from "antd";
import logo from "svg/logo.svg";
// import user from "svg/user.png";
// import { ReactComponent as LogoutIcon } from "svg/logout.svg";
import Search from "antd/es/input/Search";

const UserList = () => {
  const dataSource = [
    {
      key: "1",
      firstName: "John",
      lastName: "Brown",
      email: "test@gmail.com",
      nickName: "test",
      role: "user",
    },
    {
      key: "2",
      firstName: "Jim",
      lastName: "Green",
      email: "test@gmail.com",
      nickName: "test",
      role: "user",
    },
    {
      key: "3",
      firstName: "Joe",
      lastName: "Black",
      email: "test@gmail.com",
      nickName: "test",
      role: "user",
    },
  ];

  return (
    <div>
      <aside>
        <div>
          <img src={logo} width={200} alt="" />
        </div>

        {/* <div className="main-aside-user">
          <div className="main-aside-user-media">
            <img src={user} alt="" />
            <div className="main-aside-user-media-body">
              <div>
                <div className="main-aside-user-media-title">Amalia C.</div>
                <div className="main-aside-user-media-subtitle">Admin</div>
              </div>
              <div className="main-aside-user-media-subtitle">
                testemail@test.com
              </div>
            </div>
            <Button icon={<LogoutIcon />} className="icon-button"></Button>
          </div>
        </div> */}

        <Search />

        <ul>
          <li>
            <Filter />
          </li>
          <li>
            <Filter />
          </li>
          <li>
            <Filter />
          </li>
          <li>
            <Filter />
          </li>
          <li>
            <Filter />
          </li>
        </ul>
      </aside>

      <main className="main-content">
        <div>
          Sort by: <Filter />
        </div>
        <UserCard dataSource={dataSource} />
      </main>
    </div>
  );
};

export default UserList;
