import React from 'react';
import { useParams } from 'react-router-dom';

const Dashboard: React.FC = () => {
  const { number } = useParams<{ number?: string }>();

  return (
    <h1>Dashboard {number ? `Project ${number}` : ''}</h1>
  );
};

export default Dashboard;
