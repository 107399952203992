import React from "react";
import { useParams } from "react-router-dom";
import url from "svg/user.png";

const UserProfile: React.FC<any> = () => {
  const dataSource = [
    {
      key: "1",
      firstName: "John",
      lastName: "Brown",
      email: "test@gmail.com",
      nickName: "test",
      role: "user",
    },
    {
      key: "2",
      firstName: "Jim",
      lastName: "Green",
      email: "test@gmail.com",
      nickName: "test",
      role: "user",
    },
    {
      key: "3",
      firstName: "Joe",
      lastName: "Black",
      email: "test@gmail.com",
      nickName: "test",
      role: "user",
    },
  ];
  const { key } = useParams<{ key: string }>();
  const user = dataSource.find((user: any) => user.key === key);

  if (!user) {
    return <div>User not found</div>;
  }

  return (
    <div>
      <div>
        <div>
          <img src={url} alt="" />
          <h2>
            {user.firstName} {user.lastName}
          </h2>
          <p>Role: {user.role}</p>
        </div>
        <button>Resend Invitation Email</button>
      </div>
      <div>
        <p>Email: {user.email}</p>
        <button>Change email address</button>
      </div>
      <div>
        <h2>Basic Information</h2>
        <p>Nickname: {user.nickName}</p>
        <p>First Name: {user.firstName}</p>
        <p>Last Name: {user.lastName}</p>
        <p>Company Name: test</p>
        <p>Phone Number: +358444809252</p>
        <p>Country: Test</p>
        <p>
          Time Zone: (GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius
        </p>
      </div>
      <div>
        <h2>Personal Interests and Hobbies</h2>
        <p>Hobbies and interests: test</p>
        <p>Areas of expertise: test</p>
      </div>
      <button>Edit</button>
    </div>
  );
};

export default UserProfile;
