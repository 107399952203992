import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import Boards from "Pages/Boards/Boards";
import PublicRoute from "./PublicRoutes/PublicRoutes";
import PrivateRoute from "./PrivateRoutes/PrivateRoutes";
import Auth from "./Auth/Auth";
import UserList from "Pages/UserList/UserList";
import UserProfile from "Pages/UserProfile/UserProfile";
import MainLayout from "Pages/MainLayout/MainLayout";
import Dashboard from "Pages/Dashboard/Dashboard";

const MainLayoutWrapper: React.FC = () => (
  <MainLayout>
    <Outlet />
  </MainLayout>
);

const AppRouter: React.FC = () => {
  return (
    <Routes>
      <Route element={<MainLayoutWrapper />}>
        <Route path="/boards/:id" element={<Boards />} />
        <Route path="/boards" element={<Boards />} />
        <Route path="/user-list" element={<UserList />} />
        <Route path="/user/:key" element={<UserProfile />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dashboard/:number" element={<Dashboard />} />
        <Route
          path="app/*"
          element={
            <PrivateRoute>
              <div>Work</div>
            </PrivateRoute>
          }
        />
      </Route>
      <Route
        path="auth/*"
        element={
          <PublicRoute>
            <Auth />
          </PublicRoute>
        }
      />
      <Route path="/*" element={<div>NOT FOUND</div>} />
    </Routes>
  );
};

export default AppRouter;
