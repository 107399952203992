import React from 'react';
import './MainLayout.scss';

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return (
    <div className="main-layout">
      <div className="main-content">
        {children}
      </div>
    </div>
  );
};

export default MainLayout;
