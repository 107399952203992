import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "Pages/Login/Login";
import ForgotPassword from "Pages/ForgotPassword/ForgotPassword";
import ResetPassword from "Pages/ResetPassword/ResetPassword";
import ChangePassword from "Pages/ChangePassword/ChangePassword";
import ProfileDetails from "Pages/ProfileDetails/ProfileDetails";

const Auth = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/login" />} />
    <Route path="/forgot" element={<ForgotPassword />} />
    <Route path="/login" element={<Login />} />
    <Route path="/reset" element={<ResetPassword />} />
    <Route path="/change-password" element={<ChangePassword />} />
    <Route path="/profile-details" element={<ProfileDetails />} />
  </Routes>
);
export default Auth;
