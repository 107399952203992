import React from 'react';
import logo from "svg/logo.svg";
import styles from "./auth-aside.module.scss";

const AuthAside = () => {
  return (
    <aside className={styles['auth-aside']}>
      <div className="text-right">
        <img src={logo} className="mb-56" style={{ width: "400px" }} alt="" />
        <div className={styles['tagline']}>
          <div>Designed for Research</div>
          <div>Made for <span className={styles['brand-text-underline']}>People.</span></div>
        </div>
        <div className={styles['text']}>
          <div>Your complete online qualitative</div>
          <div>research platform</div>
        </div>

        <a className="vl-hint-link">Need help?</a>
      </div>
    </aside>
  );
};

export default AuthAside;